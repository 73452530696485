var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c(
        "div",
        [
          _c(
            "v-sheet",
            {
              staticClass: "pa-6 f13",
              attrs: { color: "#f0f7ff", rounded: "lg" },
            },
            [_vm._v(_vm._s(_vm.answerTitleHolder))]
          ),
        ],
        1
      ),
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c("v-textarea", {
                attrs: {
                  "auto-grow": "",
                  outlined: "",
                  counter: "1000",
                  rules: _vm.rules,
                  required: "",
                },
                model: {
                  value: _vm.answerModel.answer,
                  callback: function ($$v) {
                    _vm.$set(_vm.answerModel, "answer", $$v)
                  },
                  expression: "answerModel.answer",
                },
              }),
            ],
            1
          ),
          _c(
            "v-footer",
            { staticClass: "mb-4", attrs: { app: "", color: "transparent" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "f16",
                      attrs: {
                        block: "",
                        elevation: "0",
                        rounded: "",
                        color: "primary",
                        large: "",
                        disabled: !_vm.valid,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.validate()
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Submit")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }